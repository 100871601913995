import React from 'react';
import './AboutUs.css';
import Header from './components/Header';

const AboutUs = () => {
    return (
        <div className="about-us-container">
            <Header />

            {/* Top title background section */}
            <div className="contact-header">
                <h1 className="contact-title">Contact Us</h1>
            </div>

            {/* Main content area */}
            <div className="content-container">
                {/* Left content section */}
                <div className="left-content">
                    {/* Services Section */}
                    <div className="services-intro">
                        <h3 className="service-main-title">Check out our services!</h3>
                        <p className="service-description">
                            We offer flexible options that can be tailored to the specific needs of each project.
                            <br />
                            For inquiries, please contact us at <a href="mailto:info@mf-ai.co.uk">info@mf-ai.co.uk</a>.
                            <br />
                            For more details, please visit <a href="https://mf-ai.co.uk/" target="_blank" rel="noopener noreferrer">https://mf-ai.co.uk/</a>.
                        </p>
                    </div>

                    {/* Services Grid Section */}
                    <div className="services-grid">
                        <div className="service-card">
                            <hr className="service-line" />
                            <h3 className="service-title-bold">Phage Discovery</h3>
                            <hr className="service-line" />
                            <p className="service-description">Comprehensive discovery service targeting <b>ESKAPE pathogens</b>, offering end-to-end solutions for phage identification, characterization, and validation.</p>
                        </div>
                        <div className="service-card">
                            <hr className="service-line" />
                            <h3 className="service-title-bold">Phage-as-a-Service</h3>
                            <hr className="service-line" />
                            <p className="service-description">Tailored phage development and optimization for <b>specific bacterial targets</b>, ensuring effective and precise bacterial control solutions.</p>
                        </div>
                        <div className="service-card">
                            <hr className="service-line" />
                            <h3 className="service-title-bold">Phage Consulting</h3>
                            <hr className="service-line" />
                            <p className="service-description"><b>Expert consulting</b> for phage product selection, including regulatory compliance analysis and detailed reports adhering to US and EU standards.</p>
                        </div>
                    </div>

                    {/* Team Section */}
                    <section className="team">
                        <h3 className="service-main-title">Meet our team!</h3>
                        <p className="section-description">Get to know the talented individuals driving the AI-aided Phage Prediction platform at Medicine Frontier.</p>
                        <div className="team-list">
                            <p className="service-text">Dr. Rio Li, Dr. Hanshuo Lu, Dr. Yueyi Cai</p>
                        </div>
                    </section>
                </div>

                {/* Right form section */}
                <div className="right-form">
                    <p className="form-note">Please note: all fields are required.</p>
                    <form className="contact-form">
                        <div className="form-group">
                            <label htmlFor="first-name">First Name</label>
                            <input type="text" id="first-name" name="first-name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="last-name">Last Name</label>
                            <input type="text" id="last-name" name="last-name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea id="message" name="message" rows="5" required />
                        </div>
                        <button type="submit" className="submit-btn">Submit</button>
                    </form>
                </div>
            </div>

            {/* Footer */}
            <footer className="footer">
                <p>Special thanks to our brilliant software architect Faiz Mohammad Khan for the assistance with web design and deployment.</p>
            </footer>
        </div>
    );
};

export default AboutUs;