import React from 'react';
import NavMenu from './components/NavMenu'; // Import the NavMenu component

const Layout = ({ children }) => {
    return (
        <div>
            <NavMenu />

            {/* To wrap the routes */}

            {children}
        </div>
    );
};

export default Layout;
