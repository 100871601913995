// Header.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import wikiLogo from '../image/wiki.png'; // 导入图片

const Header = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('id'); // Default search type is 'id'
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim() === '') {
      setError('Cannot search an empty ID/description');
      return;
    }
    setError('');
    // Navigate to the appropriate URL based on the selected search type
    navigate(`/search/${searchType}/${searchTerm}`);
  };


  return (
    <div className="d-flex justify-content-start align-items-center pt-3 pb-2 mb-3 border-bottom">
      <Link to="/">
        <img src={wikiLogo} alt="Phage Wiki Logo" className="wiki-logo" style={{ width: '50px', height: '50px', marginRight: '20px', cursor: 'pointer' }} />
      </Link>
      <div className="d-flex flex-column">
        <Link to="/" className="text-decoration-none text-dark">
          <h1 className="h2 m-0">Phage Wiki</h1>
        </Link>
        <p className="find-your-own-phage m-0">Find Your Own Phage</p>
      </div>
      <form className="form-inline my-2 my-lg-0 ml-auto" onSubmit={handleSearchSubmit} style={{ marginLeft: '20px' }}>
        <select
          className="form-control mr-sm-2 custom-select"
          name="searchType"
          value={searchType}
          onChange={handleSearchTypeChange}
        >
          <option value="id">ID</option>
          <option value="description">Keyword</option>
        </select>

        <input
          className="form-control mr-sm-2"
          type="search"
          placeholder={`Search by ${searchType === 'id' ? 'Phage ID' : 'Keywords'}`}
          aria-label="Search"
          name="searchTerm"
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <button className="btn btn-outline-success my-2 my-sm-0" type="submit">
          Search
        </button>
      </form>
      {error && <div style={{ color: 'darkred', marginLeft: '20px' }}>{error}</div>}
    </div>
  );
};

export default Header;