import React, { useState } from 'react';
import './NavMenu.css';

const NavMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the menu's open state
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="nav-container">
            {/* Nav Icon */}
            <div className={`nav-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="line" />
                <div className="line" />
                <div className="line" />
            </div>

            {/* Pop-down Menu */}
            <div className={`menu ${isOpen ? 'show' : ''}`}>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">Contact Us</a></li>
                </ul>
            </div>
        </div>
    );
};

export default NavMenu;
